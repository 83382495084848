import colors from "./colors";

const fonts = {
  general: "'Graphik LCG', sans-serif",
  graphik: "'Graphik LCG', sans-serif",
  operetta: "'Operetta', sans-serif",
};

const theme = Object.freeze({
  colors: {
    // ui kit 1.0
    bgSec: colors.lightGrey,
    borderMain: colors.hrOpacity,
    black: colors.black,
    white: colors.white,
    tomato: colors.tomato,
    heavyAccent: "#0E2C54",

    // New UI MVP v10
    almostBlack: "#030303",
    darkIndigoBg: colors.darkIndigo,
    darkIndigoText: colors.darkIndigo,
    inactive: colors.slate,
    actionBlue: colors.lightNavy,
    pinkBg: colors.lightPink,
    pinkBgAccent: colors.lightPink2,
    pinkBgAccent2: colors.lightPink3,
    pinkBgAccent3: colors.brightPink,
    veryLightBlueBg: colors.aquaHaze,
    veryLightBlueBg2: colors.aquaHaze2,
    dangerBright: colors.tomato,
    dullOrange: colors.dullOrange,
    mashBlack: colors.clay,
    blueishGrey: colors.casper,
    serviceGrey: colors.slate2,
    serviceGrey2: colors.slate3,
    emerald: colors.greenHaze,

    social: {
      facebook: colors.sanMarino,
      twitter: colors.dodgerBlue,
      linkedin: colors.deepCerulean,
      footer: colors.frenchGrey,
      email: colors.frenchGrey,
    },

    buttons: {
      someButtons: "#FAECE4",
    },
  },
  fonts,
  screens: {
    //UIkit
    desktop: "1366px",
    desktopN: 1366,
    tabletLandscape: "1024px",
    tabletLandscapeN: 1024,
    tablet: "768px",
    tabletN: 768,
    phone: "500px",
    phoneN: 500,
  },
  dropShadow: {
    submenu: "0 2px 14px 0 rgba(0, 0, 0, 0.11)",
    remove: "0 10px 30px 0 rgba(255, 0, 0, 0.25)",
    removeHover: "0 15px 30px 0 rgba(205, 0, 0, 0.25)",
  },
});

export default theme;
