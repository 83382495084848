import { ComponentProps, FC } from "react";

import styled from "@emotion/styled";

import { theme } from "@/ui/theme";

type ButtonProps = ComponentProps<"button">;

const Button: FC<ButtonProps> = styled.button`
  border-radius: 5px;
  font-family: ${theme.fonts.graphik};
  font-size: 16px;
  font-weight: 500;
  transition: all 0.125s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 104px;
  border: 0;
  cursor: pointer;

  &[disabled] {
    cursor: default;
  }
`;

export const Blue = styled(Button)`
  box-shadow: 0 4px 6px 0 rgba(48, 83, 214, 0.24),
    0 1px 2px 0 rgba(21, 44, 131, 0.27);
  background-color: var(--light-navy);
  color: var(--very-light-pink);
  line-height: ${26 / 16};
  padding: 11px 24px;

  &:hover {
    box-shadow: 0 4px 6px 0 rgba(48, 83, 214, 0.24),
      0 1px 2px 0 rgba(21, 44, 131, 0.51);
    background-color: #124082;
  }

  &:active {
    box-shadow: 0 4px 6px 0 rgba(48, 83, 214, 0.24),
      0 1px 2px 0 rgba(21, 44, 131, 0.51);
    background-color: #0e3267;
  }

  &[disabled] {
    background-color: rgba(21, 80, 166, 0.5);
    box-shadow: 0 4px 6px 0 rgba(48, 83, 214, 0),
      0 1px 2px 0 rgba(21, 44, 131, 0);
  }
`;

export const BlueTransparent = styled(Button)`
  box-shadow: inset 0 0 0 1px #1550a6;
  background-color: transparent;
  color: var(--light-navy);
  line-height: ${26 / 16};
  padding: 11px 24px;

  &:hover {
    color: var(--light-navy);
    box-shadow: inset 0 0 0 2px var(--light-navy);
  }

  &:active {
    color: #0e3267;
    box-shadow: inset 0 0 0 2px #0e3267;
  }

  &[disabled] {
    color: rgba(21, 80, 166, 0.5);
    box-shadow: inset 0 0 0 1px rgba(21, 80, 166, 0.4);
  }
`;

export const Red = styled(Button)`
  font-weight: 400;
  box-shadow: inset 0 0 0 1px rgba(255, 0, 0, 0.4);
  background: var(--white);
  color: ${theme.colors.dangerBright};
  line-height: ${26 / 16};
  padding: 11px 24px;

  &:hover {
    color: ${theme.colors.dangerBright};
    box-shadow: inset 0 0 0 2px ${theme.colors.dangerBright};
  }

  &:active {
    color: #822121;
    box-shadow: inset 0 0 0 2px #7f1d1d;
  }

  &[disabled] {
    color: rgba(226, 56, 56, 0.5);
    box-shadow: inset 0 0 0 1px rgba(255, 0, 0, 0.4);
  }
`;

export const RedOutline = styled(Button)`
  font-weight: 600;
  box-shadow: inset 0 0 0 1px rgba(226, 56, 56, 0.2);
  background: rgba(0, 0, 0, 0);
  color: #e23838;
  line-height: ${26 / 16};
  padding: 11px 24px;

  &:hover {
    color: ${theme.colors.dangerBright};
    box-shadow: inset 0 0 0 2px ${theme.colors.dangerBright};
  }

  &:active {
    color: #822121;
    box-shadow: inset 0 0 0 2px #7f1d1d;
  }

  &[disabled] {
    color: rgba(226, 56, 56, 0.5);
    box-shadow: inset 0 0 0 1px rgba(255, 0, 0, 0.4);
  }
`;

export const LightBlueSmall = styled(Button)`
  background-color: #eef4fa;
  font-size: 14px;
  font-weight: 500;
  color: var(--light-navy);
  line-height: ${26 / 16};
  padding: 5px 12px;

  &:hover {
    color: var(--light-navy);
    background-color: #cbdef1;
  }

  &:active {
    color: var(--dark-indigo);
    background-color: #bacee2;
  }

  &[disabled] {
    background-color: #eef4fa;
    color: rgba(21, 80, 166, 0.5);
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Blue,
  BlueTransparent,
  LightBlueSmall,
  Red,
  RedOutline,
};
