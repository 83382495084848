import React from "react";
import { Field } from "react-final-form";
import ReCAPTCHA from "react-google-recaptcha-enterprise";

export const RecaptchaEnterprise = () => (
  <Field name="recaptcha_token">
    {({ input }) => (
      <ReCAPTCHA sitekey={process.env.RECAPTCHA_SITEKEY} {...input} />
    )}
  </Field>
);
