import Image from "next/image";
import React, { ComponentProps, FC } from "react";

import LoadingImage from "../../assets/loader_3x.png";
import css from "./index.module.css";

type LoadingProps = ComponentProps<"div"> & {
  colorSpinner?: string;
  useSvg?: boolean;
};

const SVGLoaderImage: FC<ComponentProps<"svg">> = ({ fill = "#FFF" }) => (
  <svg viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40 72A32 32 0 018 40 32 32 0 0140 8a32 32 0 0132 32c0 1.1-.9 2-2 2s-2-.9-2-2c0-15.4-12.6-28-28-28S12 24.6 12 40s12.6 28 28 28c1.1 0 2 .9 2 2s-.9 2-2 2z"
      fill={fill}
    />
  </svg>
);

const Loading: FC<LoadingProps> = ({ children, colorSpinner, useSvg }) => (
  <div className={css.container} data-testid="loading-spinner">
    <div className={css.spinnerRound} style={{ color: colorSpinner }}>
      {useSvg ? (
        <SVGLoaderImage fill={colorSpinner} />
      ) : (
        <span className={css.image}>
          <Image alt="loader" src={LoadingImage} />
        </span>
      )}
    </div>
    {children ? <div className={css.spinnerText}>{children}</div> : null}
  </div>
);

export default Loading;
