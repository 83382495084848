import React, { Children, FC } from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import cn from "classnames";

import { ActiveLinkProps } from "./types";

export const ActiveLink: FC<ActiveLinkProps> = ({
  activeClassName,
  children,
  ...props
}) => {
  const router = useRouter();
  const child = Children.only(children);

  if (child == null) {
    throw new Error("Link must contain at least one child element");
  }
  return (
    <Link {...props}>
      {React.cloneElement(
        child as React.ReactElement<
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          any,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          string | React.JSXElementConstructor<any>
        >,
        {
          className: cn(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
            typeof child === "object" ? (child as any).props?.className : "",
            activeClassName
              ? {
                  [activeClassName]:
                    router?.asPath === props.as ||
                    router?.pathname === props.href,
                }
              : undefined
          ),
          href: props.href || props.as,
        }
      )}
    </Link>
  );
};
