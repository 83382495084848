/* eslint-disable import/no-anonymous-default-export */
// Names are the courtesy of http://chir.ag/projects/name-that-color/
export default {
  // ui-kit 1.0
  lightGrey: "#F5F5F6",
  hrOpacity: "rgba(175,196,210, .3)",
  white: "#FFF",
  darkIndigo: "#0e2c54",
  slate: "#546882",
  slate2: "#6B8394",
  slate3: "#8EA1B8",
  dullOrange: "#e27b38",
  lightNavy: "#1550a6",
  lightPink: "#fef5ef",
  lightPink2: "#faece4",
  lightPink3: "#f5ddd0",
  brightPink: "#f0b4aa",
  aquaHaze: "#e9eef3",
  aquaHaze2: "#F4F6F9",
  tomato: "#e23838",
  clay: "#2B2F41",
  casper: "#AFC4D2",
  greenHaze: "#009C48",

  // ui-kit 1.0 END
  //Deprecated

  black: "#2b2f41",
  sanMarino: "#4367b2",
  dodgerBlue: "#1da1f4",
  deepCerulean: "#027ab6",
  frenchGrey: "#CDCDD2",
};
