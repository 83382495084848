// import { countries } from "../data/countries";
import { get, set } from "lodash-es";

// export const formatNumber = (n) => {
//   if (!n) {
//     return n;
//   }
//   const str = n.toString();
//   if (!n.source) {
//     if (!Number.isFinite(Number(n))) {
//       return "";
//     }
//     return str;
//   }

//   if (str !== n.source) {
//     const ret = n.source.match(/^((-?(\.[0-9]*|[0-9]+\.?[0-9]*))|-).*$/);
//     if (ret && ret.length > 1) {
//       const m = ret[1].match(/^(-?)(\..*)/);
//       if (m && m.length > 2) {
//         return `${m[1]}0${m[2]}`;
//       }
//       return ret[1];
//     }
//     return "";
//   }
//   const rest = n.source.replace(new RegExp(`^${str}`), "");
//   return str + (rest === "." ? rest : "");
// };

// export const formatNaturalNumber = (n) => {
//   if (!n) {
//     return n;
//   }
//   const str = n.toString();
//   if (!n.source) {
//     if (!Number.isFinite(Number(n))) {
//       return "";
//     }
//     return str;
//   }

//   if (str !== n.source) {
//     const ret = n.source.match(/^((-?([0-9]+))|-).*$/);
//     if (ret && ret.length > 1) {
//       return ret[1];
//     }
//     return "";
//   }
//   const rest = n.source.replace(new RegExp(`^${str}`), "");
//   return str + rest;
// };

// export const parseNaturalNumber = (value) => {
//   const parsed = value && Number.parseInt(value, 10);
//   if (value !== parsed.toString()) {
//     parsed.source = value;
//   }
//   return parsed;
// };

// export const parseNumber = (value) => {
//   const parsed = value && Number.parseFloat(value);
//   if (value !== parsed.toString()) {
//     parsed.source = value;
//   }
//   return parsed;
// };

export const composeRecordValidators =
  (...validators) =>
  (values) =>
    validators
      .filter((v) => typeof v === "function")
      .reduce((acc, validator) => ({ ...acc, ...validator(values) }), {});

export const composeFieldValidators =
  (...validators) =>
  (value, values) =>
    validators
      .filter((v) => typeof v === "function")
      .reduce(
        (error, validator) => error || validator(value, values),
        undefined
      );

export const recordsMatch = (a, b, message) => (values) => {
  const vA = get(values, a);
  const vB = get(values, b);
  if (!vA || !vB) {
    return undefined;
  }
  if (vA === vB) {
    return undefined;
  }
  const err = message || `Fields ${a} and ${b} must match`;
  return {
    [a]: err,
    [b]: err,
  };
};
/**
 * @deprecated use required()
 */

export const requiredField =
  (errorMessage = "Required") =>
  (value) =>
    value ? undefined : errorMessage;

export const regexpField = (regex, message) => (value) =>
  regex.test(value) ? undefined : message || "Invalid value";

export const min =
  (minValue, errorMessage = `Must be greater than ${minValue}`) =>
  (value) =>
    Number(value) >= minValue ? undefined : errorMessage;

export const max =
  (maxValue, errorMessage = `Must be less than ${maxValue}`) =>
  (value) =>
    Number(value) <= maxValue ? undefined : errorMessage;

// export const validCountryCode = (value) =>
//   value ? (countries[value] ? undefined : "Invalid country") : undefined;

export const nonNegativeNumber = (value) => {
  if (value) {
    const n = Number.parseFloat(value);
    return Number.isFinite(n) && n >= 0 ? undefined : "Invalid number";
  }
  return undefined;
};

export const validPostCode = (
  message = "Please specify the correct post code of the property"
) =>
  withEmpty((value) =>
    /^([\dA-PR-UWYZ][\dA-HK-Y][\dAEHMNPRTVXY]?[\dABEHMNPRV-Y]? {1,2}\d[ABD-HJLN-UW-Z]{2}|GIR 0AA)$/.test(
      value.toString()
    )
      ? undefined
      : message
  );

// New validations

/**
 * @deprecated use "isEmpty" from "src/lib/validators.ts"
 */
export const isEmpty = (value) =>
  value == null || (typeof value === "string" && value.trim().length === 0);

export const withEmpty = (f) => (value, values, props) => {
  if (!isEmpty(value)) {
    return f(value, values, props);
  } else {
    return undefined;
  }
};

export const validEmail = (msg = "Incorrect email") =>
  withEmpty((value) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z\-]+\.)+[A-Za-z]{2,}))$/.test(
      value.toString()
    )
      ? undefined
      : msg
  );

export const filled = (errorMessage = "Incomplete") =>
  withEmpty((value) =>
    typeof value === "string"
      ? value.includes("_")
        ? errorMessage
        : undefined
      : errorMessage
  );

export const isNot =
  (reference, errorMessage = "Invalid value") =>
  (value) =>
    reference === value ? errorMessage : undefined;

export const required =
  (errorMessage = "Required") =>
  (value) =>
    isEmpty(value) ? errorMessage : undefined;

export const autocompleteRequired =
  (errorMessage = "Required") =>
  (value) =>
    isEmpty(value?.suggestion) ? errorMessage : undefined;

export const requiredDepending =
  (otherFieldName, errorMessage = "Required") =>
  (value, values) =>
    isEmpty(value) && get(values, otherFieldName) !== true
      ? errorMessage
      : undefined;

export const isTrue = (errorMessage) => (value) =>
  value !== true ? errorMessage : undefined;

export const matches = (otherFieldName, errorMessage) => (value, values) =>
  value === get(values, otherFieldName) ? undefined : errorMessage;

export const fieldsMatch =
  (field1Name, field2Name, errorMessage) => (value, values) => {
    const v1 = get(values, field1Name);
    const v2 = get(values, field2Name);
    if (!v1 || !v2) {
      return undefined;
    }
    if (v1 === v2) {
      return undefined;
    }
    const err =
      errorMessage || `Fields ${field1Name} and ${field2Name} must match`;
    return {
      [field1Name]: err,
      [field2Name]: err,
    };
  };
export const geField = (otherField, errorMessage) =>
  withEmpty((value, values) =>
    isEmpty(get(values, otherField)) ||
    Number(value) >= Number(get(values, otherField))
      ? undefined
      : errorMessage
  );

export const leField = (otherField, errorMessage) =>
  withEmpty((value, values) =>
    isEmpty(get(values, otherField)) ||
    Number(value) <= Number(get(values, otherField))
      ? undefined
      : errorMessage
  );

export const less = (than, errorMessage) =>
  withEmpty((value) =>
    Number(value) < Number(than) ? undefined : errorMessage
  );

export const le = (than, errorMessage) =>
  withEmpty((value) =>
    Number(value) <= Number(than) ? undefined : errorMessage
  );

export const greater = (than, errorMessage) =>
  withEmpty((value) =>
    Number(value) > Number(than) ? undefined : errorMessage
  );

export const ge = (than, errorMessage) =>
  withEmpty((value) =>
    Number(value) >= Number(than) ? undefined : errorMessage
  );

// End of rules

const join = (rules) => (value, values, props) =>
  rules
    .map((rule) => rule(value, values, props))
    .find((error) => typeof error === "object" || Boolean(error));

export const createValidator =
  (rules) =>
  (data = {}, props) => {
    const errors = {};
    for (const key of Object.keys(rules)) {
      // concat enables both functions and arrays of functions
      const rule = join([].concat(rules[key]));
      const error = rule(get(data, key), data, props);
      if (typeof error === "string") {
        set(errors, key, error);
      } else if (error != null && typeof error === "object") {
        for (const fieldName of Object.keys(error)) {
          set(errors, fieldName, error[fieldName]);
        }
      }
    }
    return errors;
  };

export const maxLength = (max, errorMessage = "Too long") =>
  withEmpty((value) => (value.length > max ? errorMessage : undefined));

export const minLength = (min, errorMessage = "Too short") =>
  withEmpty((value) => (value.trim().length < min ? errorMessage : undefined));

export const correctTime = (errorMessage = "Incorrect time") =>
  withEmpty((value) =>
    value.trim().length === 5 &&
    Number(value.slice(0, 2)) < 24 &&
    Number(value.slice(3)) < 60 &&
    /[^1-9A-Za-z]/.test(value)
      ? undefined
      : errorMessage
  );

export const enumRequired =
  (errorMessage = "Required") =>
  (v) =>
    typeof v === "string" && !v.toLowerCase().includes("unknown")
      ? undefined
      : errorMessage;

export const validateAge = (errorMessage = "Incorrect age") =>
  withEmpty((value) =>
    Number(value) >= 18 && Number(value) <= 110 ? undefined : errorMessage
  );

export const uploadValidate = (
  FileElementType,
  errorMessage = "Wrong file type."
) =>
  withEmpty((value) =>
    value && value[0]?.type === FileElementType.UPLOAD_ERROR
      ? errorMessage
      : undefined
  );
